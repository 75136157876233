export interface Intersection {
  visible: boolean
  scrollPosition?: number
  elementPosition?: ElementPosition
  scrollPositionPercentage?: number
  element?: Element
  scrollingUp: boolean
}

interface ElementPosition {
  top: number
  bottom: number
  height: number
}

export default class InViewObserver {
  observer?: IntersectionObserver
  intersection: Intersection
  onScrollCallback?: (intersection: Intersection) => void
  detectScroll: boolean
  hasListener: boolean
  rootElement: HTMLElement | null | undefined

  constructor(
    callback?: (intersection: Intersection) => void,
    root?: string,
    detectScroll: boolean = false,
    onScrollCallback?: (intersection: Intersection) => void
  ) {
    this.intersection = {
      visible: false,
      scrollingUp: false
    }
    this.hasListener = false
    this.detectScroll = detectScroll ?? false
    this.onScrollCallback = onScrollCallback
    const scrollFunction = () => {
      this.onScroll()
    }

    if (process.client) {
      this.rootElement = root ? document.getElementById(root) : undefined
      this.observer = new IntersectionObserver(
        ([entry]) => {
          this.intersection.visible = entry.isIntersecting

          if (callback) {
            callback(this.intersection)
          }

          if (this.detectScroll && !this.hasListener) {
            if (entry.isIntersecting) {
              this.hasListener = true
              window.addEventListener('scroll', scrollFunction)
            }
          }
        },
        {
          root: this.rootElement ?? null,
          rootMargin: '0px',
          threshold: 0
        }
      )
    }
  }

  onScroll() {
    const progressInfo: string[] = []
    progressInfo['window'] = window.innerHeight
    progressInfo['top'] = this.intersection?.element?.getBoundingClientRect().top
    progressInfo['bottom'] = this.intersection?.element?.getBoundingClientRect()?.bottom
    progressInfo['height'] = this.intersection?.elementPosition?.height

    const scrollableDistance = window.innerHeight + progressInfo['height']
    const scrolled = window.innerHeight - progressInfo['top']

    const pct = (scrolled / scrollableDistance) * 100

    const pastScrollPositionPercentage = this.intersection.scrollPositionPercentage ?? 0
    this.intersection.scrollPositionPercentage = pct > 100 ? 100 : pct > 0 ? pct : 0

    if (
      this.intersection?.scrollPositionPercentage != null &&
      this.intersection.scrollPositionPercentage > 0
    ) {
      if (this.intersection?.scrollPositionPercentage > pastScrollPositionPercentage) {
        this.intersection.scrollingUp = false
      } else {
        this.intersection.scrollingUp = true
      }
    }

    if (this.onScrollCallback) {
      this.onScrollCallback(this.intersection)
    }
  }

  observe(element: Element) {
    if (this.observer) {
      this.observer.observe(element)

      if (this.intersection) {
        this.intersection.element = element
        this.intersection.elementPosition = {
          top: element.getBoundingClientRect().top + document.documentElement.scrollTop + 100,
          bottom: element.getBoundingClientRect().bottom + document.documentElement.scrollTop,
          height: (element as HTMLElement).offsetHeight
        }
      }
    }
  }
}
